@import '../../../../scss/theme-bootstrap';

.sticky-footer {
  background-color: $color-black;
  padding: 0;
  width: 100vw;
  position: relative;
  margin-#{$ldirection}: -50vw;
  #{$ldirection}: 50%;
  top: -22px;
  @include breakpoint($landscape-up) {
    padding: 0 29px;
    height: 65px;
    top: -30px;
  }
  .sticky-footer-active & {
    position: fixed;
    bottom: 0;
    z-index: 9;
    margin: 0;
    #{$ldirection}: 0;
    height: 41px;
    top: auto;
    .sticky-footer__item {
      min-height: 145px;
      bottom: -50px;
      @include breakpoint($landscape-up) {
        min-height: 120px;
        bottom: -41px;
      }
      &.active {
        bottom: 0;
        .sticky-footer__item-close {
          display: inline-block;
          @include breakpoint($landscape-up) {
            display: none;
          }
        }
        .ada-enabled & {
          .sticky-footer__item-close {
            display: inline-block;
          }
        }
      }
      &-trigger {
        line-height: get-line-height(17px, 41px);
      }
    }
    &.sticky-footer-disabled-mobile {
      .page-product & {
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
    }
    &.sticky-footer-disabled-pc {
      .page-product & {
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
    }
  }
  &__wrapper {
    color: inherit;
    height: inherit;
    background-color: inherit;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &-pc {
      display: none;
      @include breakpoint($landscape-up) {
        display: flex;
        justify-content: space-between;
      }
    }
    &-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      @include breakpoint($landscape-up) {
        display: none;
      }
      .is-sticky & {
        padding: 0;
      }
      .sticky-footer__item {
        flex: 1;
        text-align: center;
        padding: 0 10px;
      }
    }
    &-items {
      display: flex;
      align-items: center;
      background-color: inherit;
    }
  }
  &-#{$ldirection}-section,
  &-#{$rdirection}-section {
    display: flex;
    flex: 1;
    background-color: inherit;
    @include breakpoint($landscape-up) {
      flex: none;
    }
  }
  &-#{$ldirection}-section {
    justify-content: flex-start;
  }
  &-#{$rdirection}-section {
    justify-content: flex-end;
  }
  &__item {
    padding: 0 15px;
    display: inline-block;
    border-#{$rdirection}: 1px solid $color-light-gray;
    line-height: 1;
    background-color: inherit;
    position: relative;
    text-align: center;
    &.disabled {
      display: none;
    }
    &.active {
      @include breakpoint($landscape-up) {
        bottom: 0;
        transition: bottom 0.2s ease-in;
        animation-duration: 1s;
        animation-fill-mode: both;
      }
      & > .sticky-footer__item-content {
        display: block;
      }
    }
    &-content {
      font-family: $font--mono;
      font-size: 13px;
      padding-bottom: 5px;
      line-height: 1.2;
      display: none;
      button {
        font-family: $font--mono;
        font-size: 13px;
      }
    }
    &-cta-trigger {
      text-decoration: underline;
      cursor: pointer;
    }
    &:last-child {
      border: none;
    }
    &-trigger {
      font-family: $NeueHaasGrotesk;
      font-size: 17px;
      line-height: 1.5;
      padding: 0;
      text-transform: none;
      &-link {
        text-decoration: underline;
      }
    }
    &-close {
      display: none;
      .icon--close {
        padding: 0 5px;
        fill: $color-white;
        width: 20px;
        height: 11px;
        stroke: $color-white;
        stroke-width: 50px;
        @include breakpoint($landscape-up) {
          width: 24px;
          height: 12px;
        }
      }
    }
  }
  a.sticky-footer__item-content-link {
    text-decoration: underline;
  }
  .sticky-livechat__headline {
    line-height: 1.45;
  }
  .custom-link {
    border: none;
    color: inherit;
    background: transparent;
    padding: 0;
    &:hover,
    &:active,
    &:focus {
      background: none;
      border: none;
      color: inherit;
      padding: 0;
    }
  }
  .icon--chat {
    width: 20px;
    height: 25px;
    @include breakpoint($landscape-up) {
      width: 18px;
      height: 24px;
    }
  }
}
